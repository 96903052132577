<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <base-material-card
      class="px-5 py-3"
    >
      <template
        v-slot:heading
      >
        <div
          class="d-flex flex-wrap align-center"
          style="margin-top: -10px; margin-bottom: -10px"
        >
          <div
            class="text-h4 font-weight-light"
          >
            Résumé de la semaine par tâches
          </div>
          <v-spacer/>
          <v-btn-toggle
            v-model="weekSelector"
            tile
            color="deep-green"
            group
          >
            <v-btn
              value="previous"
              class="m-t0 mb-0 pa-0"
            >
              Semaine précédente
            </v-btn>

            <v-btn
              value="current"
              class="m-t0 m-b0 pa-0"
            >
              Semaine courante
            </v-btn>
          </v-btn-toggle>
        </div>
      </template>
      <v-simple-table>
        <thead>
        <tr>
          <th>
            Tâche
          </th>
          <th
            v-for="(day, i) in dates"
            :key="i"
          >
            <p class="primary--text text-right font-weight-medium mb-0">
                <span :class="{'grey--text': day > todayDateISO, 'font-weight-bold': day <= todayDateISO}">
                  {{
                    new Date(day).toLocaleDateString('fr-CH', {
                      weekday: 'long',
                      month: 'long',
                      day: 'numeric',
                    })
                  }}
                </span>
            </p>
          </th>
          <th class="text-right">
            Total
          </th>
          <th class="text-right">
            Pro Rata
          </th>
        </tr>
        </thead>

        <tbody>
        <tr
          v-for="task in orderedTasks"
          :key="task.id"
        >
          <td>{{ task.name }}</td>
          <td
            v-for="day in dates"
            :key="day"
            class="text-right"
          >
            {{
              minutesToHoursMinutes(activitiesByTaskByDate[task.name][day].reduce((acc, activity) => acc + activity.minutes, 0), true)
            }}
          </td>
          <td class="text-right text-body-2 font-weight-light grey--text">
            {{
              minutesToHoursMinutes(Object.values(activitiesByTaskByDate[task.name]).flat().reduce((acc, activity) => acc + activity.minutes, 0))
            }}
          </td>
          <td class="text-right text-body-2 font-weight-light grey--text">
            {{
              (Object.values(activitiesByTaskByDate[task.name]).flat().reduce((acc, activity) => acc + activity.minutes, 0) / totalMinutes * 100).toFixed(1)
            }} %
          </td>
        </tr>
        </tbody>
        <tfoot>
        <tr class="pt-5 text-color2 grey--text">
          <th class="font-weight-regular">
            Total
          </th>
          <th
            v-for="(minutes, date) in totalMinutesByDate"
            :key="date"
            class="text-right font-weight-light text-body-2 pt-4"
          >
            <p>{{ minutesToHoursMinutes(minutes) }}</p>
          </th>
          <th
            class="text-right font-weight-light text-body-2 pt-4"
          >
            <p>{{ minutesToHoursMinutes(totalMinutes) }}</p>
          </th>
          <th/>
        </tr>
        </tfoot>
      </v-simple-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import gql from 'graphql-tag'
  import { eachDayOfInterval, startOfWeek, endOfWeek, subWeeks } from 'date-fns'
  import Vue from 'vue'
  import { minutesToHoursMinutes, toDateISO } from '../../../utils'

  export default {
    name: 'Dashboard',

    data () {
      return {
        today: new Date(),
        activities: [],
        weekSelector: 'current',
      }
    },

    computed: {
      startDate () {
        let firstDay = startOfWeek(this.today, { weekStartsOn: 1 })
        if (this.weekSelector === 'previous') {
          firstDay = subWeeks(firstDay, 1)
        }
        return firstDay
      },
      endDate () {
        let lastDay = endOfWeek(this.today, { weekStartsOn: 1 })
        if (this.weekSelector === 'previous') {
          lastDay = subWeeks(lastDay, 1)
        }
        return lastDay
      },
      dates () {
        return eachDayOfInterval({
          start: this.startDate,
          end: this.endDate,
        }).map(toDateISO)
      },
      todayDateISO () {
        return toDateISO(this.today)
      },
      startDateISO () {
        return toDateISO(this.startDate)
      },
      endDateISO () {
        return toDateISO(this.endDate)
      },
      orderedTasks () {
        const tasks = {}
        for (const activity of this.activities) {
          tasks[activity.task.id] = activity.task
        }
        return Object.values(tasks).sort((a, b) => (b.sort - a.sort) !== 0 ? (b.sort - a.sort) : a.compare(b))
      },
      totalMinutes () {
        return this.activities.reduce((acc, activity) => acc + activity.minutes, 0)
      },
      totalMinutesByDate () {
        const buckets = {}
        for (const date of this.dates) {
          buckets[date] = 0
        }
        for (const activity of this.activities) {
          buckets[activity.date] += activity.minutes
        }
        return buckets
      },
      activitiesByTaskByDate () {
        const buckets = {}
        for (const activity of this.activities) {
          if (!(activity.task.name in buckets)) {
            buckets[activity.task.name] = []
            for (const date of this.dates) {
              buckets[activity.task.name][date] = []
            }
          }
          if (activity.date in buckets[activity.task.name]) {
            buckets[activity.task.name][activity.date].push(activity)
          }
        }
        return buckets
      },
    },

    watch: {
      '$route.name': {
        handler () {
          this.today = new Date()
          this.$nextTick(() => this.$apollo.queries.activities.refetch(
            {
              startDate: this.startDate,
              endDate: this.endDate,
              user: localStorage.getItem('me'),
            },
          ))
        },
        deep: true,
        immediate: true,
      },
      startDate () {
        this.$nextTick(() => this.$apollo.queries.activities.refetch(
          {
            startDate: this.startDate,
            endDate: this.endDate,
            user: localStorage.getItem('me'),
          },
        ))
      },
    },

    mounted () {
      Vue.prototype.$reloadDashboard = () => {
        this.$apollo.queries.activities.refetch()
      }
    },

    methods: {
      minutesToHoursMinutes,
    },

    apollo: {
      activities () {
        return {
          query: gql`
          query ($startDate: String!, $endDate: String!, $user: String!) {
            activities (filter: {_and: [{ user: { id: { _eq: $user } }}, {date: { _gt: $startDate } }, {date: { _lte:  $endDate } }]}, limit: -1 ) {
              id
              date
              minutes
              service {
                id
                name
              }
              task {
                id
                name
              }
              customer {
                id
                first_name
                last_name
              }
              client {
                id
                name
              }
            }
          }
            `,
          variables: {
            startDate: this.startDate,
            endDate: this.endDate,
            user: localStorage.getItem('me'),
          },
          error (error) {
            const code = error.networkError?.statusCode
            if (code === 401) {
              localStorage.removeItem('jwt')
              this.$router.push({ name: 'Login' })
            }
          },
        }
      },
    },
  }
</script>
